
// Fonts
@import url(https://fonts.googleapis.com/css?family=Changa:200,300,400,500,600,700,800);
@import url('/css/uthman-font.css');

// Bootstrap
@import "bootstrap-grid";

@import "mixins/all";

// paper dashboard
@import "paper/paper-dashboard";
@import "paper/paper-rtl";


@import "frontend/all";

.form-control{
    background: #fff;
    border: 1px solid #ccc;
    font-family: $font-family;
}
.card label, .control-label, .btn, .modal{
    font-family: $font-family;
}
body.rtl{
    .btn{
        font-family: $font-family;
    }
    .datepicker-dropdown{
        opacity: 1;
        visibility: visible;
    }
    .form-control{
        direction: rtl;
    }
    .select2{
        direction: rtl;
        font-family: $font-family;
        height: 40px;
    }
    .select2-container--bootstrap .select2-selection--single{
        height: 40px;
        line-height: 25px;
    }
    .select2-container--bootstrap .select2-selection--single .select2-selection__arrow{
        height: 40px;
        right: auto;
        left: 12px;
    }
}

.reverse{
    float: left;
}
//.card-btn{
//    margin-top: -50px !important;
//}
//body.rtl{
//    .reverse{
//        float: left !important;
//    }
//}

.popover{
    max-width: 300px !important;
    width: 300px !important;
}

.text-center{
    text-align: center;
}

.no-radius-left{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.no-radius-right{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-radius{
    border-radius: 0;
}
.auth-body{
    background-color: #66615b;
}

[class^="ti-"], [class*=" ti-"]{
    font-weight: normal !important;
}

.label{
    color: #fff !important;
}

// .slick-arrow{
//     width: 40px;
//     height: 40px;
//     line-height: 45px;
//     display: block;
//     text-align:center;

//     border: 1px solid #000;
//     border-radius: 100%;

//     color : #000;
//     font-size: 20px;

//     position: absolute;
//     top: 50%;
//     left: 10%;

//     transform: translate(-50%, -50%);

//     z-index: 10;

//     cursor : pointer;

//     &:hover{
//         background:#000;
//         color: #fff;
//     }

//     &.slick-arrow-next{
//         transform: translate(50%, -50%);
//         left: auto;
//         right: 10%;
//     }
// }

.product-price{
    font-size: 16px;
}
.product-description{
    margin-top: 20px;
}

.card .nav li.active a{
    color : #000;
}

.datepicker{
    opacity: 1 !important;
    visibility: visible !important;
}

.control-label input[type="checkbox"]{
    margin-left: 15px;
}
.search-container{
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: $color-red;
	.search-label{
		@include font-size(2);
		text-align: center;
		margin-bottom: 10px;
		color: #fff;
	}
}
.site-footer{
	background-color: #2c3e50;
	background-image: url('images/footer-bg.jpg');
	background-size: cover;
	padding-top: 60px;
	.footer-logo img{
		margin-bottom: 30px;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.footer-list{
		li {
			margin-bottom: 15px;
			a{
				color: #fff;
				@include font-size(1.6);
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}

	.social-networks{
		text-align: center;
		li{
			display: inline-block;
			margin-left: 10px;
			a{
				color : #fff;
				@include font-size(2.4);
			}
		}
	}

	.bottom-footer{
		margin-top: 30px;
		color: #fff;
		border-top: 1px solid #fff;
		padding-top: 10px;
		padding-bottom: 10px;
		a{
			color: #fff;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

@media (min-width: 767px){
	.site-footer{

		.social-networks{
			text-align: right;
			margin-top: 150px;
		}
	}
}
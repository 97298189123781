.profile-inner{
	ul li.active a{
		border-bottom-color: $color-red
	}
}

.profile.user-profile-picture{
	text-align: center;

	@include border-radius(100%);

	width: 100px;
	height: 100px;
	display: block;
	overflow: hidden;
	margin: 0 auto;

	margin-bottom: 30px;
	margin-top: -50px;

	position: relative;
	z-index: 1000;
}

.subscription-total{
	padding-top: 10px;
	padding-bottom: 10px;

	background-color: $color-green;
	@include border-radius(4px);

	color: #fff;
	@include font-size(1.8);
	.usd{
		margin-left: 15px;
		margin-right: 15px;
	}
	.subscription-fee{
		color: #000;
		float: left;
		font-weight: 800;
		margin-left: 15px;
	}
}

.credit-cards{
	list-style: none;
	margin: 0 0 15px;
	padding: 0;
	li{
		display: inline-block;
		margin:0 20px;
		&:first-child{
			margin-right: 0;
		}
	}
}

.profile-reading-status{
	position: relative;
	padding-top: 30px;
	border-right: 1px solid rgba(0,0,0,.3);

	@include font-size(1.6);
	&:first-child{
		margin-top: -15px;
	}

	a{
		color: $color-black;
		&:hover{
			color: $color-red;
		}
	}
	font-weight: 700;

	.reading-progress{
		float: left;
		color: $color-green
	}
	.circle{
		width: 20px;
		height: 20px;
		display: block;
		@include border-radius(100%);
		background-color: $color-green;
		float: right;

		position: absolute;
		right: -10px;
	}
}
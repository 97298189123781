.reading-header{
	margin-top: -200px;
}
.reading-inner{
	ul li.active a{
		border-bottom-color: $color-red;
	}
}

@media (min-width: 768px){
	.reading-inner{
		margin-top: 240px;
	}
}

.reading-wrapper{
	padding-top: 15px;
	padding-bottom: 15px;
	border: 1px solid $color-black;
	@include border-radius(8px);
	margin-bottom: 15px;
	background-color: #fff;
	background-image: url('images/reading-card-bg.png');
	background-size: cover;

	width: 100%;
	display: block;

	text-align: center;

	font-weight: 600;
	@include font-size(1.6);

	@include css3-prefix('transition', all 0.3s ease-in-out);

	&:hover{
		@include box-shadow(inset 0 0 0 7px rgba($color-green, .5) );
	}

	> span{
		color : $color-black;
		width: 100%;
		display: block;
		clear: both;
		margin-bottom: 20px;
	}

	.reading-card-number{
		color: $color-green;
	}
	.reading-card-label{
		@include font-size(2);
		font-weight: 400;
		color: #363636;
	}
	.reading-card-first-name{
		@include font-size(2.6);
		color: $color-orange;
		font-weight: 800;
	}
	.reading-card-last-name{
		@include font-size(2.2);
	}
	.reading-card-list{
		span{
			@include font-size(1.8);
			font-weight: 400;
			width: auto;
			display: inline-block;
			position: relative;
			padding-left: 7px;
			color: #363636;
			&:not(:last-child){
				&:after{
					content: "";

					position: absolute;
					top: 0;
					left: 1px;

					height: 100%;
					width: 2px;
					display: block;
					background-color: $color-black;
				}
			}
		}
	}
}
.search-wrapper{
	a{
		display: block;
		color: #000;
		@include content-font();
		text-decoration: underline;
		&:last-child{
			margin-bottom: 10px;
		}
		&:hover{
			text-decoration: none;
		}
	}
}

.introductions-content, .introductions-content *{
	@include content-font( 1.8 );
}
.home-body{
	margin-top: 30px;
	margin-bottom: 30px;
}

.filter-labels{
	border-bottom: 1px solid #000;
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		height: 50px;
		li{
			height: 50px;
			line-height: 50px;
			float: right;
			&.active a{
				border-bottom: 4px solid $color-green;
			}
			a{
				line-height: 46px;
				display: block;
				padding: 0 20px;
				color: #000;
				@include font-size(1.6);
			}
		}
	}
}

@media screen and (max-width: 767px){
	.responsive-tabs{
		li{
			width: 100%;
		}
	}
}

@media screen and (min-width: 767px){
	.feature-wrapper{
		padding-right: 5px !important;
		padding-left: 5px !important;
		margin-bottom: 0 !important;
		&:nth-child(2n+1){
			clear: none !important;
		}
	}
	.responsive-tabs{
		li{
			&:first-child a{
				padding-right: 0;
			}
		}
	}
}

.home-tabs-text, .home-tabs{
	line-height: 50px;
	text-align: center;
}

.home-tabs-text{
	border-bottom: 1px solid #000;
	height: 50px;
	@include font-size(1.8);
	color: #000;
}
.home-tabs{
	height: auto;
	border: none;

	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;
		text-align: center;
		li{
			width: 100%;
			display: inline-block;
			line-height: 50px;
			border-bottom: 1px solid #000;
			a{
				line-height:50px;
				display: block;
				padding: 0 20px;
				color: #3e3e3e;
				@include font-size(1.6);
				&:hover{
					color: #000;
				}
			}
		}
		li.active a{
			color: #000;
			border-bottom: 6px solid $color-green;
			line-height: 40px;
		}
	}
}

@media (min-width: 767px ){
	.home-tabs {
		height: 50px !important;
		border-bottom: 1px solid #000 !important;
		ul li{
			width: auto;
			border-bottom: none;
		}
	}
}

.readings-wrapper{
	margin-top: 30px;
}

.features-wrapper{
	margin-bottom: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
	background-color: #e84c3d;
}
.feature-wrapper{
	padding-right: 15px;
	padding-left: 15px;
	text-align: center;
	margin-bottom: 30px;
	&:nth-child(2n+1){
		clear: right;
	}
	.icon{
		color: #fff;
		margin-bottom: 15px;
	}
	.feature-text{
		@include font-size(1.6);
		font-family : $font-family;
		color: #fff;
	}
}

.testimonials-wrapper{
	margin-bottom: 30px;
}

.testimonial-wrapper{
	text-align: center;
	border: 1px solid $color-black;
	padding:15px;
	@include border-radius(4px);
	font-family: $font-family;

	&.inner{
		margin-bottom: 30px;
	}

	&:not(.inner){
		margin-top: 65px;
		min-height: 200px;
	}

	&:not(.inner) .profile-picture{
		margin-top: -65px;
	}

	.profile-picture{
		margin: 0 auto;
		text-align: center;
		@include border-radius(100%);
		overflow: hidden;
		height: 100px;
		width: 100px;
		display: block;

		img{
			@include box-shadow(inset 0 0 0 7px rgba($color-green, .5) );
		}
	}
	.content{
		@include font-size(1.8);
		padding: 15px 30px;
	}
	.author{
		text-align: right;
		@include font-size(1.8);
		.name{
			font-weight: 800;
		}
	}
	.geo{
		@include font-size(1.8);
		i{
			color: #e84c3d;
		}
	}
}
.course-content{
	.course-wrapper:nth-child(2n){
		background-color: rgba(#dff0d8, .3);
	}
}
.course-wrapper{
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dotted rgba(0,0,0,.35);
	&:hover{
		background-color:#eee !important;
		border-bottom: 1px solid #000;
	}
	a{
		color: #000;
	}
}

.course-description,.course-description p, .course-description span{
	@include content-font();
}
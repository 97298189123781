.frontend-card{
	border: 1px solid darken(#eee, 10%);
	padding: 15px;
	margin-bottom: 15px;

	&.message-owner{
		background-color: #eee;
	}

	@include border-radius(4px);

	.header{
		.title{
			margin-top: 0;
		}
	}
}
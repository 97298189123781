.site-header{
	text-align: center;
	.logo{
		text-align: center;
		margin-top: 15px;
		img{
			display: inline !important;
		}
	}
	.header-btns{
		.btn:not(:last-child){
			margin-left: 10px;
		}
	}
	.dropdown{
		margin-bottom: 30px;
	}
}

.header-banner{
	min-height: 200px;
	display: block;
	&:not(.home){
		background-image: url('images/header-bg.jpg');
		background-size: cover;
	}
	position: relative;

	&.inner-page{
		height: 250px;
	}

}



.header-slider{
	overflow: hidden;
	.header-slider-item{
		height: 400px;
		width: 100%;
		display: block;
		float: right;

		background-size: cover;
	}

	.slick-arrow{
		position: absolute;
		top: 50%;
		right: 50px;
		z-index: 9999;

		color: #fff;
		@include font-size(4);

		&.slick-arrow-next{
			right: auto;
			left: 50px;
		}
	}
}

.header-banner-caption{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 90%;

	@include css3-prefix( transform, translate(-50%, -50%) );

	z-index: 9999;
	color: #fff;
	margin-right: 15px;
	.select2-selection{
		background: transparent !important;
		border: 2px solid #fff;
		.select2-selection__placeholder, .select2-selection__rendered{
			color: #fff !important;
		}
	}
}

.search-control{
	background-color: transparent !important;
	color: #fff !important;
	border: 2px solid #fff !important;
	&:focus{
		background-color: #fff !important;
		color: #000 !important;
	}
}

@media (min-width: 767px){
	.site-header{
		height: 120px;
		display: block;

		.logo{
			margin-top: 5px;
		}

		.header-btns{
			margin-top: 50px;
		}


	}
	.header-banner-caption{
		width: 40%;
		display: block;
	}
}

@media (min-width: 992px) {
	.header-banner-caption{
		@include css3-prefix(transform, translate(0, -50%));
		left: auto;

		right: -webkit-calc( ( 100% - 970px ) / 2 );
		right: calc( ( 100% - 970px ) / 2 );
	}
}

@media (min-width: 1200px) {
	.header-banner-caption{
		right: -webkit-calc( ( 100% - 1170px ) / 2 );
		right: calc( ( 100% - 1170px ) / 2 );
	}
}

.user-avatar{
	text-align: left !important;
	margin-top: 25px;
	&.lesson-page{
		margin-top: 0 !important;
	}
	span{
		display: inline-block;
		vertical-align: middle;
	}
	.user-name{
		@include font-size(1.8);
		color: $color-light-blue;
		span{
			color: $color-orange;
		}
	}
	.user-profile-picture{
		width: 50px;
		height: 50px;
		overflow: hidden;

		@include border-radius(100%);
		margin-right: 10px;
	}
}

.header-menu{
	position: relative;
	z-index: 100;
	background-color: rgba(#ecf0f1, .75);
	min-height: 50px;

	.menu{
		list-style:none;
		margin: 0;
		padding: 0;
		text-align: center;
		li{
			width: 100%;
			line-height: 50px;
			display: inline-block;
			a{
				padding: 0 20px;
				line-height: 50px;
				display: block;
				color: #515151;
				&:hover, &.active{
					background-color: $color-green;
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 991px){
	.site-header .dropdown-menu {
		display: block;
	}
}

@media screen and (min-width: 767px){
	.menu{
		display: block !important;
		height: 50px;
	}
	.menu li{
		width: auto !important;
	}
	.header-banner{
		margin-top: -50px;
	}

	.site-header .dropdown{
		margin-bottom: 0;
	}

	.site-header .logo{
		text-align: right;
		img{
			display: block !important;
		}
	}

}

.alert{
	margin-top: 20px !important;
}
.spinner-wrapper{
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	display: block;

	z-index: 100;
	background-color: #fff;
}

.spinner {
	margin: 0 auto;
	width: 70px;
	text-align: center;

	position: absolute;
	top: 50%;
	left: 50%;

	@include transform( translate(-50%, -50%) );
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #2d3e50;

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
	-webkit-transform: scale(0);
	transform: scale(0);
	} 40% {
	-webkit-transform: scale(1.0);
	transform: scale(1.0);
	}
}
.number{
	color: $color-light-blue !important;
	margin-left: 15px;
}

.lesson-complete{
	float:left;
	margin-left: 30px;
	margin-top: -3px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	background-color : $color-green;
	text-align: center;
	@include border-radius(4px);
	i{
		color: #fff !important;
		margin-left: 0 !important;
	}
}

.lesson-header, .lesson-footer{
	position: relative;
	z-index: 1001;

	min-height: 50px;
	display: block;

	background-color: #2d3e50;
	color: #fff;
}

.lesson-header{
	.logo img{
		float: none;
		margin: 0 auto;
	}

	.lesson-title{
		margin-top: 15px;
	}

	.reading-title{
		h4{
			line-height: 35px;
			margin-top: 25px;
		}
		a{
			color: #fff;
			&:hover{
				color: $color-green;
			}
		}
	}

	.header-lesson-title{
		margin-top: 30px;
	}

	.lesson-user-info{
		text-align: center;
		margin-bottom: 15px;
	}
}

@media (min-width: 768px){
	.lesson-header .logo img{
		float: right;
	}
	.lesson-header .lesson-user-info{
		text-align: left;
		margin-bottom: 0;
	}
}

.lesson-footer{
	.lesson-pagination{
		@include font-size(1.6);
		margin: 15px 50px 0;

		display: inline-block;
	}
}

.lesson-pagination-btn{
	margin-top: -6px;
}

@media (min-width: 768px){
	.lesson-right, .lesson-left{
		padding-left: 30px;
		padding-right: 30px;
	}
}


.lesson-title{
	@include font-size(1.6);
	color: $color-red;
	font-weight: 700;
	p{
		margin-bottom: 0;
	}
}
.lesson-description{
	.lesson-description-content{
		p{
			@include content-font(  $fontSize: 1.8, $fontWeight: normal, $lineHeight: 35px  );
			span{
				@include content-font(  $fontSize: 1.8, $fontWeight: normal, $lineHeight: 35px  );

				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	p:last-child{
		margin-bottom: 0;
	}
}

.rule-title{
	@include font-size(1.6);
	font-weight: 600;
	p{
		width: auto;
		display: inline-block;
	}
	&.example-title{
		margin-bottom: 0 !important;
	}
	.order{
		color: $color-red;
	}
	.circle{
		margin-right: 5px;
	}
	margin-bottom: 10px;
}

.circle{
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;

	@include border-radius(100%);
	margin-left: 10px;

	background-color: $color-light-blue;
}
.lesson-description{
	.example-table:last-child{
		margin-bottom: 0;
	}
}

.example-table{
	padding: 0;
	margin-bottom: 30px;
	.example-table-row{
		padding: 10px;
		border-bottom: 1px solid #ccc;

		&:last-child{
			border-bottom: 0;
		}
	}
}

.youtube-url, .audio-file{
	display: block;
	margin: 0 30px 15px;
	&:last-child{
		margin-bottom: 0;
	}
	@include font-size(1.6);

	color: #000;
	&:hover, &:focus{
		color: #000;
	}

	&.playing{
		i{
			color: $color-green;
		}
	}

	audio{
		display: none;
	}
}

.lesson-empty{
	@include font-size(2);
}

.lessons-menu-toggler{
	color: #fff;
	@include font-size(2);
	line-height: 60px;
}

.lessons-menu{
	background-color: #edf1f2;
	width: 250px;
	height: 100%;
	padding-right: 25px;
	padding-left: 25px;
	padding-bottom: 65px;

	.menu-logo img{
		margin-top: 45px;
		margin-bottom: 40px;
	}

	position: fixed;
	right: -250px;
	top: 0;
	z-index: 1000;

	&.open{
		right: 0;
	}

	@include css3-prefix('transition', right 0.3s ease-in-out);

	.menu-title{
		font-weight: bold;
		margin-top: 5px;
		color: $color-red;
		padding: 5px;
		border: 1px solid rgba(#000, .1);
	}

	.items{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			margin-bottom: 10px;
			a{
				@include font-size(1.4);
				p{
					@include font-size(1.4);
				}
				font-weight: bold;
				color: $color-black;
				&:hover, &.active{
					color: $color-red;
				}
			}
		}
	}
}

.lessons-menu{
	overflow: scroll;
}

@media (min-width: 747px){
	.lessons-menu{
		padding-top: 80px;
	}
	.menu-logo{
		display: none;
	}

	.lessons-menu{
		overflow: hidden;
	}
}

.compare-name{
	.first-name{
		color: #E88D2B
	}
}
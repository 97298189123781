$color-green: #96c634;
$color-orange : #e18f0f;
$color-black : #363636;
$color-blue : #2683be;
$color-light-blue: lighten( $color-blue, 10% );
$color-red : #e84c3d;

$contnet-font: UthmanTN1B;

.app{
	font-family: $font-family;
	color: #000;
}

.btn-bordered-4{
	@include border-radius(4px);
}

.btn-black{
	color: #000;
	border-color: #000;

	&:hover{
		background:#000;
		color: #fff;
		border-color: #000;
	}
}

.btn-green{
	color: $color-green;
	border-color: $color-green;
	&:hover{
		background: $color-green;
		color: #fff;
		border-color: $color-green;
	}

	&.btn-fill{
		color: #fff;
		background: $color-green;

		&:hover{
			border-color: darken( $color-green,10% );
			background: darken( $color-green,10% );
		}
	}
}
.btn-red{
	color: $color-red;
	border-color: $color-red;
	&:hover{
		background: $color-red;
		color: #fff;
		border-color: $color-red;
	}

	&.btn-fill{
		color: #fff;
		background: $color-red;

		&:hover{
			border-color: darken( $color-red,10% );
			background: darken( $color-red,10% );
		}
	}
}
.font-red{
	color: $color-red;
}

.btn-paypal-blue{
	color: #0092b3;
	border-color: #0092b3;
	&:hover{
		background: #0092b3;
		color: #fff;
		border-color: #0092b3;
	}

	&.btn-fill{
		color: #fff;
		background: #0092b3;

		&:hover{
			border-color: darken( #0092b3,10% );
			background: darken( #0092b3,10% );
		}
	}
}

.font-paypal-blue{
	color: #0092b3;
}

.btn.btn-18{
	@include font-size(1.8);
}

.btn-white{
	color: #fff;
	border-color: #fff;
	&:hover{
		color: #000;
		border-color: #fff;
		background-color: #fff;
	}
	&.btn-disabled{
		color: darken( #ccc , 20% );
		border-color: darken( #ccc , 20% );

		&:hover{
			color: darken( #ccc , 20% );
			border-color: darken( #ccc , 20% );
			background-color: transparent;
		}
	}
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
	background:#fff;
	color: #3e3e3e !important;
}
body.rtl {
	.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle){
		border-right: 1px solid #ccc;
		border-left: 0;
		@include border-radius(0 4px 4px 0)
	}

	.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child){
		border: 1px solid #ccc;
		border-right: 0;
		@include border-radius(4px 0 0 4px)

	}
}

@import "common";
@import "header";
@import "footer";
@import "home";
@import "reading";
@import "lessons";
@import "profile";
@import "articles";
@import "courses";
@import "messages";
.reverse{
	float: right;
}

body.rtl .reverse{
	float:left;
}

body.rtl .fw_print_r{
	direction: ltr;
}

.font-tahoma{
    font-family: tahoma;
}

.m-t-0{ margin-top: 0 }
.m-t-5{ margin-top: 5px }
.m-t-10{ margin-top: 10px }
.m-t-15{ margin-top: 15px }
.m-t-20{ margin-top: 20px }
.m-t-25{ margin-top: 25px }
.m-t-30{ margin-top: 30px }
.m-t-35{ margin-top: 35px }
.m-t-40{ margin-top: 40px }
.m-t-45{ margin-top: 45px }
.m-t-50{ margin-top: 50px }

.m-b-0{ margin-bottom: 0 }
.m-b-5{ margin-bottom: 5px }
.m-b-10{ margin-bottom: 10px }
.m-b-15{ margin-bottom: 15px }
.m-b-20{ margin-bottom: 20px }
.m-b-25{ margin-bottom: 25px }
.m-b-30{ margin-bottom: 30px }
.m-b-35{ margin-bottom: 35px }
.m-b-40{ margin-bottom: 40px }
.m-b-45{ margin-bottom: 45px }
.m-b-50{ margin-bottom: 50px }

.padding-5{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

@media (min-width: 768px){
    .b-l{
        border-left: 1px solid #000;
    }
}

.bordered-wrapper{
    padding: 10px 15px;
    border: 1px solid #ccc;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.no-padding{
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.no-padding-top{
	padding-top: 0 !important;
}

.centered{
    margin-top: 100px;
    margin-bottom: 100px;
}

@media screen and (min-width: 767px ){
	.text-reverse{
		text-align: left;
	}

    .centered{
        margin: 0;

        position: absolute;
        top: 50%;
        left: 50%;

        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.col-xs-2-2,
.col-sm-2-2,
.col-md-2-2,
.col-lg-2-2,
.col-xs-1-7,
.col-sm-1-7,
.col-md-1-7,
.col-lg-1-7 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-2-2 {
    width: 20%;
    float: right;
}

.col-xs-1-7 {
    width: 14.285%;
    float: right;
}

@media (min-width: 768px) {
    .col-sm-2-2 {
        width: 20%;
        float: right;
    }

    .col-sm-1-7 {
        width: 14.285%;
        float: right;
    }
}

@media (min-width: 992px) {
    .col-md-2-2 {
        width: 20%;
        float: right;
    }

    .col-md-1-7 {
        width: 14.285%;
        float: right;
    }
}

@media (min-width: 1200px) {
    .col-lg-2-2 {
        width: 20%;
        float: right;
    }

    .col-lg-1-7 {
        width: 14.285%;
        float: right;
    }
}
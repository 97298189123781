.article{
	border: 1px solid rgba( $color-black, 0.1 );
	@include border-radius( 4px );
	padding-top: 15px;
	padding-bottom: 15px;

	.article-title{
		margin-top: 0;
		a{
			color: $color-green;
			&:hover{
				color: darken($color-green, 10%);
			}
		}
	}

	.article-content{
		font-family : tahoma;
		@include font-size(1.5);
	}

	.read-more-link{
		margin-top: 30px;
	}
}

.articles-wrapper{
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid rgba( $color-black, 0.1 );
}

.article-wrapper{
	border: 0;
	.article-thumbnail{
		@include border-radius(10px);
		overflow: hidden;
	}
}
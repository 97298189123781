@mixin content-font( $fontSize: 2.2, $fontWeight: 900, $lineHeight: 4rem ){
	font-family: $contnet-font !important;

	font-size: ($fontSize * 16) * 1px !important;
	font-size: $fontSize * 1rem !important;

	font-weight: $fontWeight !important;
	line-height: $lineHeight !important;
}

.content-wrapper{
	margin-bottom: 30px;
}

.qtitle{
	margin-top: 0;
	color: $color-red;
}

.qcheckgroup{
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 15px;
	border:1px solid rgba($color-black, .3);

	input[type="checkbox"],input[type="radio"] {
		display: none;
	}

	label{
		margin-bottom: 0;
		width: 100%;
		display: block;
		cursor: pointer;
	}

	.qcheckbox{
		text-align: right;
		float: left;
		width: 20px;
		height: 20px;
		border: 1px solid $color-black;

		@include font-size(1.8);
		color: $color-green;

		i{
			margin-right: -2px;
			margin-top: -1px;
			line-height: 20px;
			display: none;
		}
	}
}
.qcheckgroup input[type="checkbox"]:checked + label,
.qcheckgroup input[type="radio"]:checked + label,
.qcheckgroup input[type="checkbox"].checked + label,
.qcheckgroup input[type="radio"].checked + label
{
	.qcheckbox i{
		display: block;
	}
}
.qcheckgroup.subscribed{
	.qcheckbox{
		border-color: #ccc;
	}
	label, .qcheckbox i{
		color: #ccc;
		display: block;
	}
}

.qaccordion{
	margin-bottom: 15px;

	.qaccordion-header{
		padding: 7px 10px;
		margin-bottom: 15px;
		display: block;
		border: 1px solid rgba( $color-black, .3 );


		font-family: $font-family;
		@include font-size(1.8);
		color: $color-red;

		cursor: pointer;

		i{
			color: $color-blue;
			margin-left: 5px;
		}

		.arrow{
			float: left;
			margin-left: 30px;
			margin-top: 5px;
			&:before{
				content: "\f107";
			}
		}

		&[aria-expanded="true"] .arrow:before{
			content: "\f106";
		}

		p{
			display: inline-block;
			margin-bottom: 0;
		}
	}

	.MsoFootnoteReference, .MsoFootnoteReference span{
		font-size: 12px !important;
	}

	.qaccordion-body{
		@include content-font();
		p,span{
			@include content-font();
		}
	}
}

@media (min-width: 768px){
	.login-panel{
		margin-top: 75px;
	}
}
body.rtl .dropdown-menu{
	direction: rtl;
	text-align: right;
}
.dropdown .btn {
	text-align: right;
	width: 100%;
    display: block;
    padding: 10px 15px;
    margin: 0;
    border: 0;
    @include border-radius(0);
    font-weight: normal;
    &:hover{
    	text-decoration: none;
    }
}

.dropdown-menu > li:last-child .btn{
	    border-bottom-left-radius: 6px;
	    border-bottom-right-radius: 6px;
}

.form-filter{
	padding: 15px 0;
	border: 1px solid #ccc;
	.form-filter-title{
		padding: 0 15px;
		margin-bottom: 15px;
		background-color: #fff;
		display: inline-block;
	}
}

.checkbox-inline, .radio-inline{
	padding-left: 0;
	padding-right: 20px;
	margin: 0 0 0 15px;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
	margin-left: 0;
	margin-right: -20px;
}

.cursor-pointer{
	cursor: pointer;
}

.site-content,.site-content *{
	@include content-font();
}